import React, { useContext } from 'react';
import { Row, Col } from 'react-bootstrap';
import ReactHtmlParser from 'react-html-parser';

import { Heading, Text } from '../../common';
import { Link } from '../../common';
import { shouldDisplayFieldOffice } from '../../lib/helpers/officeUtils';
import { GlobalContext } from '../Layout';

import { getGoogleMapsUrl, transformHtmlWithLink } from '../../lib/helpers';

export default ({ headquarters, mailingAddress = {} }) => {
  if (!headquarters) return null;

  const { recommendedLocations, officeLink } = useContext(GlobalContext),
    fieldOfficeRegion = recommendedLocations.regional?.office?.fieldOfficeRegion;

  const renderHqAddress = [headquarters, mailingAddress].map((a, i) => {
    const physical = i === 0,
      key = physical ? a.fieldOfficeAddress : a;

    return (
      <Col xs="12" key={i} className="mb-8">
        {physical && (
          <Heading as="h2" color="darkYellow">
            TABC Headquarters
          </Heading>
        )}
        <Text>
          {physical ? a.title : 'TABC Headquarters'} ({physical ? 'physical address' : 'mailing address'})
        </Text>
        <Text>{key.addressLine1}</Text>
        {key.addressLine2 && <Text>{key.addressLine2}</Text>}
        <Text>{`${key.locality}, TX ${key.postalCode}`}</Text>
        {physical && (
          <Link className="my-3 lead-2" href={getGoogleMapsUrl(key)}>
            Get directions
          </Link>
        )}
        {physical && <Text>Phone: {a.fieldPhoneNumber}</Text>}
      </Col>
    );
  });

  const renderCurrentAddress = () => (
    <Col xs="12">
      <Heading as="h3" color="darkYellow">
        {fieldOfficeRegion?.entity?.name}
      </Heading>
      <Text as="div" className="mb-5">
        {ReactHtmlParser(fieldOfficeRegion?.entity?.description?.processed, { transform: transformHtmlWithLink })}
      </Text>

      {Object.keys(recommendedLocations)
        .sort()
        .map((type, i) => {
          if (!recommendedLocations[type].office) return null;

          if (type === 'field' && !shouldDisplayFieldOffice(recommendedLocations[type])) return null;

          const {
            office: { fieldOfficeAddress, title, fieldOfficeServices, fieldPhoneNumber, fieldOfficeHours },
          } = recommendedLocations[type];

          return (
            <div className="mb-6" key={type}>
              <Heading as="h4" className="mb-5">
                {type === 'regional' ? 'Regional Office' : 'Local Office'}
              </Heading>

              <Row>
                <Col md="6">
                  <Text fontWeight="bold">{title}</Text>

                  <Text>{fieldOfficeAddress.addressLine1}</Text>

                  {fieldOfficeAddress.addressLine2 && <Text>{fieldOfficeAddress.addressLine2}</Text>}

                  <Text>
                    {fieldOfficeAddress.locality}, TX {fieldOfficeAddress.postalCode}
                  </Text>

                  <Link className="mt-3 mb-3" href={getGoogleMapsUrl(fieldOfficeAddress)}>
                    Get directions
                  </Link>

                  <Text>Phone: {fieldPhoneNumber}</Text>

                  <Text as="div" className="contact-us__parsed">
                    Open: {ReactHtmlParser(fieldOfficeHours.processed, { transform: transformHtmlWithLink })}
                  </Text>
                </Col>

                <Col md="6">
                  <Text fontWeight="bold">This office can help with:</Text>
                  {fieldOfficeServices.map(({ entity: { name } }) => (
                    <Text key={name}>{name}</Text>
                  ))}
                </Col>
              </Row>
            </div>
          );
        })}
    </Col>
  );

  return (
    <Row className="mt-13">
      {!!recommendedLocations.regional.office ? renderCurrentAddress() : renderHqAddress}
      <Col xs="12">
        <Link href={officeLink} className="lead-2">
          View all TABC locations
        </Link>
      </Col>
    </Row>
  );
};
