import React, { useReducer, useContext } from 'react';
import { Row, Col } from 'react-bootstrap';
import { v4 as uuidv4 } from 'uuid';
import * as moment from 'moment';

import { Heading, Text } from '../../common';
import FormSelect from '../Form/FormSelect';
import SelectFormOptions from './SelectFormOptions';
import Submit from '../Form/Submit';
import Recaptcha from '../Recaptcha/Recaptcha';
import ContactSentConfirmation from './ContactSentConfirmation';
import ContactSentError from './ContactSentError';

import { GlobalContext } from '../Layout';

import { emailisValid } from '../../lib/helpers/validators';
import { sendEmail } from '../../services/email';

const errorMessages = {
    required: 'This is a required field',
    email: 'Email is not valid',
  },
  initialFormState = { name: '', email: '', additional: '', license: '', citation: '', selected: null };

const reducer = (state, { type, payload }) => {
  switch (type) {
    case 'UPDATE_FORM':
      return { ...state, form: { ...state.form, ...payload } };
    case 'UPDATE_ERRORS':
      return { ...state, errors: { ...state.errors, ...payload } };
    case 'UPDATE_FORM_SUBMIT_STATUS':
      return { ...state, formSubmitStatus: payload };
    case 'UPDATE_ERROR_AND_STOP_LOAD':
      return { ...state, errors: { ...state.errors, ...payload }, formSubmitStatus: 'new' };
    case 'RESET_FORM':
      return {
        ...state,
        form: { ...state.form, ...{ additional: '', license: '', citation: '', selected: null } },
        formSubmitStatus: 'new',
      };
    default:
      return state;
  }
};

export default ({ helpTopics }) => {
  const { recommendedLocations, zip } = useContext(GlobalContext),
    initialState = { formSubmitStatus: 'new', errors: {}, form: { ...initialFormState } },
    [state, dispatch] = useReducer(reducer, initialState),
    regionalOfficeName = recommendedLocations.regional?.office?.fieldOfficeRegion.entity.name,
    topics = helpTopics.map(({ entity: { fieldTopic: { entity: { entityLabel } } } }) => ({
      label: entityLabel,
      value: entityLabel,
    })),
    requestLicense = helpTopics
      .filter(({ entity: { fieldLicenseRequested } }) => fieldLicenseRequested)
      .map(
        ({
          entity: {
            fieldTopic: {
              entity: { entityLabel },
            },
          },
        }) => entityLabel
      ),
    requestCitation = helpTopics
      .filter(({ entity: { fieldCitationRequested } }) => fieldCitationRequested)
      .map(
        ({
          entity: {
            fieldTopic: {
              entity: { entityLabel },
            },
          },
        }) => entityLabel
      ),
    showLicenseField = requestLicense.includes(state.form.selected),
    showCitationField = requestCitation.includes(state.form.selected),
    moreAnswersLink = helpTopics.find(
      ({
        entity: {
          fieldTopic: {
            entity: { entityLabel },
          },
        },
      }) => entityLabel === state.form.selected
    )?.entity.fieldMoreAnswersLink,
    formInputs = [
      {
        name: 'name',
        value: state.form.name,
        placeholder: 'Name',
        required: true,
      },
      {
        name: 'email',
        value: state.form.email,
        placeholder: 'Email',
        type: 'email',
        required: true,
      },
      ...(showLicenseField
        ? [
            {
              name: 'license',
              value: state.form.license,
              placeholder: 'License or Permit no. (optional)',
              label: 'License or Permit no.',
            },
          ]
        : []),
      ...(showCitationField
        ? [
            {
              name: 'citation',
              value: state.form.citation,
              placeholder: 'Citation ID (optional)',
              label: 'Citation ID',
            },
          ]
        : []),
      {
        name: 'additional',
        type: 'textarea',
        value: state.form.additional,
        placeholder: 'Provide any additional details that might be helpful (optional)',
      },
    ];

  const onSelect = (selected) => {
    const payload = { selected };

    if (state.form.citation && !requestCitation.includes(selected)) {
      payload.citation = '';
    }

    if (state.form.license && !requestLicense.includes(selected)) {
      payload.license = '';
    }

    dispatch({ type: 'UPDATE_FORM', payload });
  };

  const onUpdateForm = ({ target: { name, value } }) => {
    if (state.errors[name]) dispatch({ type: 'UPDATE_ERRORS', payload: { [name]: null } });

    dispatch({ type: 'UPDATE_FORM', payload: { [name]: value } });
  };

  const onUpdateRecaptcha = () => dispatch({ type: 'UPDATE_ERRORS', payload: { recaptcha: false } });

  const isInvalid = async () => {
    const errors = formInputs.reduce((errors, { name, value, required, type = '' }) => {
      if (required && !value) {
        errors[name] = errorMessages.required;
      }

      if (type === 'email' && !emailisValid(value)) {
        errors[name] = errorMessages.email;
      }

      return errors;
    }, {});

    const recaptcha = (await window.grecaptcha.getResponse()) ? false : true,
      payload = { ...errors, ...(recaptcha && { recaptcha }) },
      errorExists = !!Object.keys(payload).length,
      type = errorExists ? 'UPDATE_ERROR_AND_STOP_LOAD' : 'UPDATE_ERRORS';

    dispatch({ type, payload });

    return errorExists;
  };

  const onSubmitForm = async () => {
    dispatch({ type: 'UPDATE_FORM_SUBMIT_STATUS', payload: 'submitting' });

    const invalid = await isInvalid();

    if (invalid) return;

    const {
      form: { selected, email, name, additional, license, citation },
    } = state;

    const topicData = helpTopics.find(
      ({
        entity: {
          fieldTopic: {
            entity: { entityLabel },
          },
        },
      }) => entityLabel === selected
    );

    const regionData = topicData.entity.fieldRegionEmails.find(
      ({
        entity: {
          fieldRegion: {
            entity: { entityLabel },
          },
        },
      }) => entityLabel === regionalOfficeName
    );

    const payload = {
      topic: selected,
      reference_id: uuidv4().slice(0, 32),
      timestamp: moment().toISOString(),
      sender_name: name,
      sender_email: email,
      agency_email: regionData?.entity.fieldEmail || topicData.entity.fieldHeadquartersEmail,
    };

    if (zip) {
      payload.zipcode = zip;
    }

    if (regionData?.entity.fieldCcEmail) {
      payload.cc_email = regionData.entity.fieldCcEmail;
    }

    if (license) {
      payload.license_number = license;
    }

    if (citation) {
      payload.citation_id = citation;
    }

    if (additional) {
      payload.additional_info = additional;
    }

    const res = await sendEmail(payload);

    dispatch({ type: 'UPDATE_FORM_SUBMIT_STATUS', payload: res.success ? 'submitted' : 'error' });
  };

  const {
    formSubmitStatus,
    form: { selected },
    errors,
  } = state;

  if (formSubmitStatus === 'submitted') {
    return <ContactSentConfirmation resetForm={() => dispatch({ type: 'RESET_FORM' })} />;
  }

  if (formSubmitStatus === 'error') {
    return <ContactSentError clearError={() => dispatch({ type: 'UPDATE_FORM_SUBMIT_STATUS', payload: 'new' })} />;
  }

  return (
    <Row className="mt-13 need-more-help">
      <Col xs="12">
        <Heading as="h2" color="darkYellow">
          Need more help?
        </Heading>
        <Text>
          Not finding the information you're looking for? Let us know what the issue is and we'll connect you with
          someone who can help.
        </Text>
        <Col xs="12" className="p-0 mt-4">
          <FormSelect
            className="fadeInUp"
            title="Select a topic..."
            selected={selected}
            options={topics}
            onSelect={onSelect}
            controlId="need-more-help-topic"
          />
        </Col>
        {selected && (
          <>
            <SelectFormOptions
              moreAnswersLink={moreAnswersLink}
              formInputs={formInputs}
              errors={errors}
              onUpdateForm={onUpdateForm}
            />
            <Recaptcha error={errors.recaptcha} callback={onUpdateRecaptcha} className="mt-8" />
          </>
        )}
        <Submit
          showTextWhileLoading
          disabled={!selected}
          onClick={onSubmitForm}
          className="form-label__btn mt-6"
          status={formSubmitStatus}
          type="submit"
        >
          Submit request
        </Submit>
      </Col>
    </Row>
  );
};
