import React from 'react';
import { Form } from 'react-bootstrap';

import useFaqUrl from '../../hooks/useFaqUrl';

import { Text, Link } from '../../common';
import FormInput from '../Form/FormInput';
import FormTextArea from '../Form/FormTextArea';

export default ({ moreAnswersLink, formInputs, errors, onUpdateForm }) => {
  const { faqName, faqPath } = useFaqUrl();

  return (
    <div className="mt-6" xs="12">
      <Text className="mb-5">
        For questions about this topic, fill out the form below or contact your local office directly. You can also find
        more answers on our{' '}
        <Link href={moreAnswersLink ? moreAnswersLink.url.path : faqPath}>
          {moreAnswersLink ? moreAnswersLink.title || moreAnswersLink.url.entity.entityLabel : faqName}
        </Link>{' '}
        page.
      </Text>
      {formInputs.map(({ placeholder, label, name, value, type = 'text', required = false }, i) =>
        type === 'textarea' ? (
          <Form.Group controlId={name} key={name}>
            <Form.Label className="mt-6 mb-4">
              <Text as="span">Additional Information</Text>
            </Form.Label>
            <FormTextArea
              className="custom-textarea"
              error={errors[name]}
              maxLength={1000}
              name={name}
              onChange={onUpdateForm}
              placeholder={placeholder}
              value={value}
            />
          </Form.Group>
        ) : (
          <FormInput
            className="need-more-help__input mt-2 fadeInUp"
            controlId={`need-more-help-options-${i}`}
            error={errors[name]}
            key={name}
            name={name}
            onChange={onUpdateForm}
            placeholder={placeholder}
            label={label}
            required={required}
            type={type}
            value={value}
          />
        )
      )}
    </div>
  );
};
