import * as jwt from 'jsonwebtoken';

const headers = { 'Content-Type': 'application/json' };

export const sendEmail = async (body) => {
  const token = jwt.sign({ iss: 'TABC' }, process.env.JWT_SECRET, { expiresIn: 120 });

  let payload;

  headers.Authorization = `Bearer ${token}`;

  try {
    const res = await fetch(process.env.EMAIL_URL, { method: 'POST', body: JSON.stringify(body), headers });

    if (res.ok) {
      payload = { success: true };
    } else {
      payload = { error: true };
    }
  } catch (e) {
    payload = { error: true };
  }

  return payload;
};
