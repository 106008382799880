import React from 'react';
import { DropdownButton, Dropdown } from 'react-bootstrap';

export default ({ title, options = [], onSelect, selected, className = '', controlId }) => {
  return (
    <DropdownButton
      title={selected || title}
      className={`dropdown-custom ${className} ${!selected && 'placeholder'}`}
      id={controlId}
    >
      {options.map(({ label, value }) => (
        <Dropdown.Item onClick={onSelect.bind(null, value)} as="button" key={label}>
          {label}
        </Dropdown.Item>
      ))}
    </DropdownButton>
  );
};
