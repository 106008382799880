import React from 'react';
import { Button, Row, Col } from 'react-bootstrap';
import { Heading, Text } from '../../common';

export default ({ resetForm }) => {
  return (
    <Row className="mt-13 need-more-help fadeInUp">
      <Col xs="12">
        <Heading as="h2" color="darkYellow">
          Thanks!
        </Heading>
        <Text>Your request was sent to the appropriate TABC representative.</Text>
        <Text className="mt-4">
          We'll send a copy of your message to your email for reference. In the meantime, feel free to contact the
          office directly.
        </Text>
        <Button onClick={resetForm} className="mt-8">
          Submit another request
        </Button>
      </Col>
    </Row>
  );
};
