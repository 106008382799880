import React from 'react';
import { useEffect } from 'react';

const Recaptcha = ({ error, className, callback = () => {} }) => {
  useEffect(() => {
    window.grecaptcha.ready(() => {
      window.grecaptcha.render('recaptcha', {
        sitekey: process.env.GOOGLE_RECAPTCHA_API,
        callback,
      });

      const res = document.getElementById('g-recaptcha-response');

      if (res) {
        res.setAttribute('aria-hidden', 'true');
        res.setAttribute('aria-label', 'recaptcha');
      }
    });
  }, []);

  return (
    <>
      <div className={`${className} ${error ? 'has-error' : ''}`} id="recaptcha" />
    </>
  );
};

export default Recaptcha;
