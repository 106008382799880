import React from 'react';
import { graphql } from 'gatsby';
import { MainContainer, LeftContainer, PageContainer } from '../../common';
import { styleVars } from '../../lib/helpers/style';
import Intro from '../../components/Intro/Intro';
import ZipSection from '../../components/ContactUs/ZipSection';
import HeadquartersSection from '../../components/ContactUs/HeadquartersSection';
import NeedMoreHelpSection from '../../components/ContactUs/NeedMoreHelpSection';

export const query = graphql`
  fragment contactUsContent on Drupal_NodeContactUs {
    title
    fieldH1 {
      entity {
        ... on Drupal_ParagraphIntro {
          fieldFormattedParagraph {
            processed
          }
          fieldHeadingCopy
        }
      }
    }
    fieldHeadquarters {
      entity {
        ... on Drupal_NodeOfficePage {
          fieldPhoneNumber
          fieldOfficeAddress {
            locality
            postalCode
            addressLine1
            addressLine2
          }
          title
        }
      }
    }
    fieldMailingAddress {
      locality
      postalCode
      addressLine1
      addressLine2
    }
    fieldHelpTopics {
      entity {
        ... on Drupal_ParagraphEmailMap {
          fieldTopic {
            entity {
              entityLabel
            }
          }
          fieldMoreAnswersLink {
            title
            url {
              path
              ... on Drupal_EntityCanonicalUrl {
                entity {
                  entityLabel
                }
              }
            }
          }
          fieldHeadquartersEmail
          fieldRegionEmails {
            entity {
              ... on Drupal_ParagraphRegionEmail {
                fieldRegion {
                  entity {
                    entityLabel
                  }
                }
                fieldCcEmail
                fieldEmail
              }
            }
          }
          fieldLicenseRequested
          fieldCitationRequested
        }
      }
    }
  }
  query($nid: String!) {
    drupal {
      contactUs: nodeById(id: $nid) {
        ...contactUsContent
      }
    }
  }
`;

export default ({
  data: {
    drupal: { contactUs },
  },
}) => {
  const { fieldH1, fieldHeadquarters, fieldMailingAddress, fieldHelpTopics } = contactUs;

  return (
    <>
      <MainContainer row className={`pt-8 pt-${styleVars.mobileBreakpoint}-9 pb-13 contact-us`}>
        <LeftContainer />
        <PageContainer>
          {fieldH1 && <Intro entity={fieldH1} />}
          <ZipSection />
          <HeadquartersSection mailingAddress={fieldMailingAddress} headquarters={fieldHeadquarters.entity} />
          <NeedMoreHelpSection helpTopics={fieldHelpTopics} />
        </PageContainer>
      </MainContainer>
    </>
  );
};
