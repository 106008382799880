import React, { useState, useEffect } from 'react';
import { FormControl } from 'react-bootstrap';

export default ({ onChange, error, ...attrs }) => {
  const [counter, updateCounter] = useState(0),
    { value, maxLength } = attrs;

  const onUpdateText = ({ target: { name, value } }) => {
    updateCounter(value.length);
    onChange({ target: { name, value } });
  };

  useEffect(() => {
    updateCounter(value.length);
  }, []);

  return (
    <div className="custom-textarea-container">
      <FormControl className="custom-textarea" onChange={onUpdateText} {...attrs} as="textarea" />
      {value && maxLength && (
        <p className="custom-textarea-container__counter">{maxLength - counter} characters left</p>
      )}
      {error && <p className="has-error">{error}</p>}
    </div>
  );
};
