import React, { useContext, useState, useRef } from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import { Text } from '../../common';
import { Flex } from 'rebass';
import FormInput from '../Form/FormInput';
import FormInputClose from '../Form/FormInputClose';
import { GlobalContext } from '../Layout';
import Form from '../Form/Form';
import useMountedZip from '../../hooks/useMountedZip';

export default () => {
  const { zip, getNearestOffices, zipLoad, zipError } = useContext(GlobalContext),
    [showZipInput, updateShowZipInput] = useState(!zip);
  const zipInputRef = useRef();

  useMountedZip({ updateShowZipInput, zipLoad, zip });

  const onSubmit = async ({ zip }) => {
    try {
      const nearestOffices = await getNearestOffices({ zip });

      if (nearestOffices?.error) return { error: true, payload: { zip: zipError } };
    } catch (e) {
      return { error: true, payload: { zip: zipError } };
    }

    return { success: true };
  };

  return (
    <Row>
      <Col xs="12">
        <Text size="large" mT={3} mB={6}>
          For all other needs, we'll connect you with your local office.
        </Text>
        <Text size="large" mT={3} mB={6}>
          Enter your ZIP code below to find out which office can help.
        </Text>
        <Flex className="mt-4 contact-us__zip-section">
          {zip && !showZipInput ? (
            <>
              <Text className="contact-us__zip-display" fontSize={[8]} fontWeight="bold">
                ZIP code: {zip}
              </Text>
              <Button
                onClick={updateShowZipInput.bind(null, true)}
                className="contact-us__zip-change-link"
                variant="link"
              >
                Change ZIP code
              </Button>
            </>
          ) : (
            <Form
              showTextWhileLoading
              isValid={({ value }) => value.length === 5}
              formProps={{ className: 'contact-us__zip-form' }}
              values={{ zip: '' }}
              submitText={'Submit'}
              submitProps={{ className: 'form-label__btn' }}
              onSuccess={() => updateShowZipInput(false)}
              onSubmit={onSubmit}
            >
              {({ onChange, values, errors, resetField }) => (
                <div className="zipcode-wrapper">
                  <FormInput
                    type="zip"
                    name="zip"
                    error={errors['zip']}
                    onChange={onChange}
                    value={values.zip}
                    placeholder="Enter ZIP code"
                    label="ZIP code"
                    className="contact-us__zip-input"
                    controlId="contact-us-zip"
                    ref={zipInputRef}
                  />
                  {values.zip && <FormInputClose onClick={() => resetField({ name: 'zip', targetRef: zipInputRef })} />}
                </div>
              )}
            </Form>
          )}
        </Flex>
      </Col>
    </Row>
  );
};
