import React from 'react';
import { Button, Row, Col } from 'react-bootstrap';
import { Heading, Text } from '../../common';

export default ({ clearError }) => {
  return (
    <Row className="mt-13 need-more-help fadeInUp">
      <Col xs="12">
        <Heading as="h2" color="darkYellow">
          Houston, we have a problem!
        </Heading>
        <Text>We encountered a problem while trying to send your request.</Text>
        <Text className="mt-4">Please try again later.</Text>
        <Button onClick={clearError} className="px-5 py-2 mt-8">
          Try again
        </Button>
      </Col>
    </Row>
  );
};
